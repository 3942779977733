<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">

                   <!-- <div>
                        <router-link to="/Admin/tgbizlb/form">
                            <el-button type="primary" icon="el-icon-plus">添加</el-button>
                        </router-link>
                    </div>-->
                    <div style="width:130px;">
                        <el-input v-model="where.nic" placeholder="请输入昵称"></el-input>
                    </div>
                    <div style="width:140px;">
                        <el-select v-model="where.type" placeholder="选择审核状态">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="待审核" value="1"></el-option>
                            <el-option label="审核通过" value="2"></el-option>
                            <el-option label="审核未通过" value="3"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange"
                                        range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期"></el-date-picker>
                    </div>

                    <div>
                        <el-button icon="el-icon-search" @click="get_store_list()">条件筛选</el-button>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div class="admin_main_block_right" style="width:600px;">
                    <div>
                       <!--<utton type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button>-->
                    </div>
                </div>
            </div>



            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange">
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="id" label="#" fixed="left" width="100px"></el-table-column>
                    <el-table-column label="用户" >
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.toux||require('@/assets/default_pic.png')"
                                             width="100%"/>
                                        <img
                                                slot="reference"
                                                :src="scope.row.toux||require('@/assets/default_pic.png')"
                                                :alt="scope.row.toux"
                                                style="max-height: 60px;max-width: 60px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                                <dd class="table_dl_dd_all" :title="scope.row.nic">{{ scope.row.nic }}</dd>
                            </dl>
                        </template>
                    </el-table-column>

                    <el-table-column prop="jieb" label="套餐名称"></el-table-column>
                    <el-table-column prop="jinge" label="套餐金额"></el-table-column>

                    <el-table-column label="支付截图" >
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="600">
                                        <img :src="scope.row.zhifjt||require('@/assets/default_pic.png')"
                                             width="100%"/>
                                        <img
                                                slot="reference"
                                                :src="scope.row.zhifjt||require('@/assets/default_pic.png')"
                                                :alt="scope.row.zhifjt"
                                                style="max-height: 60px;max-width: 60px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                            </dl>
                        </template>
                    </el-table-column>



                    <el-table-column label="审核状态" >
                        <template slot-scope="scope">
                            <el-tag type="gray" v-if="scope.row.type==1">待审核</el-tag>
                            <el-tag type="success" v-if="scope.row.type==2">通过</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.type==3">审核不通过</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" width="120px">
                        <template slot-scope="scope">
                            <div>{{scope.row.chuangjsj|formatDate}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="220px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="verify_click(scope.row.id)">审核</el-button>
                            <!--<el-button icon="el-icon-tickets" @click="read_store_info(scope.row)">查看</el-button>-->
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total"
                                   :total="total_data" :page-size="page_size"
                                   :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                list: [],
                total_data: 0, // 总条数
                page_size: 20,
                current_page: 1,
                select_id: '',
                dialogVisible: false,
                info: [],
                store_verify: 0,
                where: {
                    times: [],
                },
            };
        },
        watch: {},
        computed: {},
        methods: {
            handleSelectionChange: function (e) {
                let ids = [];
                e.forEach(v => {
                    ids.push(v.id);
                });
                this.select_id = ids.join(',');
            },
            get_store_list: function () {
                let where = {};
                where.page = this.current_page;
                where.params = this.where;
                this.$get(this.$api.getXianxdd, where).then(res => {
                    this.page_size = res.data.per_page;
                    this.total_data = res.data.total;
                    this.current_page = res.data.current_page;
                    this.list = res.data;
                    this.info = res.data.data;


                })
            },
            read_store_info(e) {
                this.info = e;
                this.store_verify = this.info.store_verify;
                this.dialogVisible = true;
            },
            read_pic(e) {
                return [e];
            },

            // 分页改变
            current_change: function (e) {
                this.current_page = e;
                this.get_store_list();
            },
            // 删除处理
            del: function (id) {
                let _this = this;
                if (this.$isEmpty(id)) {
                    return this.$message.error('请先选择删除的对象');
                }
                this.$post(this.$api.delXianxdd, {id: id}).then(function (res) {
                    if (res.code == 200) {
                        _this.get_store_list();
                        return _this.$message.success("删除成功");
                    } else {
                        return _this.$message.error("删除失败！" + res.msg);
                    }
                });
            },
            verify_click: function (id) {
                this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                    confirmButtonText: '同意',
                    cancelButtonText: '拒绝',
                    type: 'info'
                }).then(() => {
                    this.$post(this.$api.xianxddVerifyChange, {id: id, type: 2}).then(() => {
                        this.$message.success('成功审核');
                        this.get_store_list();
                    });

                }).catch(() => {
                    this.$post(this.$api.xianxddVerifyChange, {id: id, type: 3}).then(() => {
                        this.$message.info('已拒绝');
                        this.get_store_list();
                    });

                });
            },
        }
        ,
        created() {
            this.get_store_list();
        }
        ,
        mounted() {
        }
    }
    ;
</script>
<style lang="scss" scoped>
    .store_list_ul li {
        border-bottom: 1px solid #efefef;
        margin: 0 0 30px 0;
        padding-bottom: 15px;
        color: #666;

        h4 {
            margin-bottom: 15px;
            color: #333;
        }
    }
</style>
